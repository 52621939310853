<template>
    <AppCard
        title="External"
        theme="light_gray">
        <div class="grid gap-4">
            <AppSelect
                name="map_service_version"
                label="PC Miler Version"
                :model-value="customerGroup.map_service_version"
                :url="url"
                :options="page.props.mapServiceVersions" />
            <AppInput
                name="macropoint_id"
                label="Macropoint ID"
                :model-value="customerGroup.macropoint_id"
                :url="url"
                class="mt-4" />
            <CustomerTrackingForm
                :customer="customerGroup"
                url="customers.groups.tracking-service" />
        </div>
    </AppCard>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    customerGroup: Object
});

const page = usePage();

const url = route('customers.groups.services.update', props.customerGroup);
</script>
