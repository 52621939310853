import { usePage } from '@inertiajs/vue3';
import { uniqBy, flatMap } from 'lodash-es';

export function useAuth() {
    const page = usePage();

    const user = computed(() => page.props?.auth?.user);

    const permissions = computed(() =>
        user.value ? uniqBy(flatMap(user.value.roles, 'permissions'), 'name').map(permission => permission.name) : []
    );

    function can(permissionName) {
        return permissions.value.includes(permissionName);
    }

    return {
        user,
        permissions,
        can
    };
}
