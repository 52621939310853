<template>
    <AppHeader
        :title="$t('Loadboard')"
        :icon-component="IconLoadboard">
        <template #actions>
            <LoadFilterDropdown
                class="max-w-48 2xl:hidden"
                :root-url="route('loads.index')"
                :filters="filters" />
            <AppFilterPills
                class="hidden gap-x-2 border-r border-iel-gray pr-3 md:ml-auto 2xl:flex"
                :root-url="route('loads.index')"
                :filters="filters" />
            <div>
                <AppButton
                    id="add-load-button"
                    icon="far fa-circle-plus"
                    permission="create_new_load"
                    @click="openLoadCreateModal">
                    {{ $t('New Load') }}
                </AppButton>
            </div>
            <LoadLayoutToggle @change="router.visit(route('loads.index'))" />
        </template>
    </AppHeader>
    <AppCard
        :title="`${load.id}`"
        theme="blue"
        content-class="!p-0 flex">
        <template #title>
            <div class="flex flex-col gap-2 text-xs text-white sm:text-sm lg:flex-row lg:gap-6 lg:text-xs xl:text-sm">
                <span class="font-bold uppercase">{{ load.customer?.name ?? '' }} #{{ load.po_number }}</span>
                <div>
                    <span class="mr-1 font-bold">{{ $t('Carrier') }}:</span>
                    <span>{{ load.carrier?.name }}</span>
                </div>
                <div :class="load.driver2 ? 'flex flex-col gap-2 md:flex-row md:gap-4 lg:gap-6' : ''">
                    <div class="gap-1 lg:flex">
                        <span class="mr-1 font-bold">{{ load.driver2 ? $t('Driver 1') : $t('Driver') }}:</span>
                        <span>{{ load.driver?.name }}</span>
                        <a
                            :href="`tel:${load.driver?.phone_number}`"
                            class="font-medium underline">
                            {{ load.driver?.phone_number }}
                        </a>
                    </div>

                    <div
                        v-if="load.driver2"
                        class="gap-1 lg:flex">
                        <span class="mr-1 font-bold">{{ $t('Driver 2') }}:</span>
                        <span>{{ load.driver2.name }}</span>
                        <a
                            :href="`tel:${load.driver2.phone_number}`"
                            class="font-medium underline">
                            {{ load.driver2.phone_number }}
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template #nav>
            <div class="my-2 flex items-center justify-start gap-2 md:my-0">
                <p
                    class="text-iel-light-gray"
                    v-text="load.is_tonu ? 'TONU' : load.is_canceled ? 'CANCELED' : ''"></p>
                <a
                    v-if="load.claim"
                    href="#load-flags-detail-section">
                    <AppIcon
                        name="fas fa-gavel"
                        class="text-white" />
                </a>
                <a
                    v-if="load.carrier_incident"
                    href="#load-flags-detail-section">
                    <AppIcon
                        name="fas fa-handshake"
                        class="text-white" />
                </a>
                <Link
                    :href="route('loads.index')"
                    aria-label="return to all loads">
                    <AppIcon
                        name="far fa-arrows-minimize"
                        class="text-white" />
                </Link>
                <LoadCardActionMenu :load="load" />
            </div>
        </template>
        <TabGroup
            :selected-index="selectedTab"
            vertical
            @change="i => (selectedTab = i)">
            <TabList class="flex flex-col bg-iel-blue py-2">
                <Tab
                    v-for="(icon, name) in phases"
                    :id="`load-${name}-phase-tab`"
                    :key="name"
                    class="relative flex flex-col items-center p-2 text-white">
                    <AppIcon
                        :name="icon"
                        class="mb-px text-xl" />
                    <span class="text-sm capitalize">{{ name }}</span>
                    <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center ui-not-selected:hidden">
                        <span class="h-4 w-2 bg-white [clip-path:polygon(100%_0%,100%_100%,0%_50%)]"></span>
                    </div>
                </Tab>
            </TabList>

            <TabPanels class="grow overflow-hidden">
                <TabPanel
                    v-for="phase in ['setup', 'plan', 'ship', 'bill']"
                    :key="phase">
                    <div
                        class="small-scrollbar flex flex-col items-start gap-5 overflow-y-hidden overflow-x-scroll whitespace-nowrap bg-iel-light-gray p-2 md:flex-row md:items-center"
                        tabindex="0">
                        <template
                            v-for="task in tasksByPhase[phase]"
                            :key="task.id">
                            <LoadCheckboxButton
                                :id="`load-${task.name.replaceAll('_', '-')}-task-button`"
                                :class="{ hidden: task.name === 'dispatch' && !load.stops.length }"
                                permission="edit_load_tasks"
                                :checked="task.is_complete"
                                :load="load"
                                :task="task"
                                :disabled="task.type === 'manual' && task.is_complete"
                                @complete-task="handleTaskClick(task)">
                                {{ task.title ? task.title : startCase(task.name) }}
                            </LoadCheckboxButton>
                        </template>
                    </div>
                    <LoadDetailPanels :load="load" />
                </TabPanel>

                <!-- Done -->
                <TabPanel>
                    <LoadDetailPanels :load="load" />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    </AppCard>
</template>

<script setup>
import { ref, toRef } from 'vue';
import { router } from '@inertiajs/vue3';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { startCase } from 'lodash-es';
import { allFilters } from '@/Composables/useFilters.js';
import Echo from 'laravel-echo';
import IconLoadboard from '/resources/img/icon-loadboard.svg';
import Pusher from 'pusher-js';
import { Toast } from '@/Composables/useSwal.js';

const phases = {
    setup: 'fal fa-memo-circle-check',
    plan: 'fal fa-clipboard-list',
    ship: 'fal fa-truck',
    bill: 'fal fa-circle-dollar',
    complete: 'fal fa-circle-check'
};

const props = defineProps({
    load: Object
});

const { tasksByPhase, handleTaskClick } = useTasks(toRef(props, 'load'));

const { open: openLoadCreateModal } = useModal('LoadCreateModal');

const { filters } = allFilters();

const selectedTab = ref(Object.keys(phases).indexOf(props.load.phase));

window.Pusher = Pusher;

const laravelEcho = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_CLIENT_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: false,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER
});

laravelEcho
    .private(`loads.${props.load.id}`)
    .listen('LoadWasUpdated', () => {
        router.reload({
            preserveScroll: true,
            only: ['load']
        });
    })
    .listen('MacropointOrderEvent', payload => {
        Toast.fire({
            title: payload.macropointResponse.message,
            icon: payload.macropointResponse.result
        });
    });
</script>
