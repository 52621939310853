<template>
    <tr>
        <td class="md:w-16">
            <div class="my-4 sm:my-0">
                <div class="flex items-start gap-x-2">
                    <p class="text-sm font-semibold text-iel-darkest-gray">{{ document.file_name }}</p>
                </div>
                <div class="mt-1 flex items-center gap-x-2 text-xs text-iel-dark-gray">
                    <div>
                        <p class="whitespace-nowrap">
                            <time :datetime="document.updated_at">{{ formatDate(document.updated_at) }}</time>
                        </p>
                        <p class="whitespace-nowrap">
                            <span>By: {{ document.submitted_by }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-1 flex gap-x-2">
                <AppButton
                    :id="`documents-list-${document.id}-actions-view-document-button`"
                    variant="outline"
                    icon="fal fa-eye"
                    @click="open(document)">
                    <span class="sr-only">View {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`document-list-${document.id}-actions-edit`"
                    variant="outline"
                    icon="fal fa-pen"
                    permission="update_load_documents"
                    @click="edit(document)">
                    <span class="sr-only">Edit {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :href="getDownloadRoute(object, document)"
                    :download="`${document.file_name}`"
                    variant="outline"
                    icon="fal fa-file-arrow-down">
                    <span class="sr-only">Download {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`document-list-${document.id}-actions-delete`"
                    variant="outline"
                    class="border-iel-dark-red text-iel-dark-red"
                    icon="far fa-trash-can"
                    :disabled="document.loading"
                    permission="delete_load_documents"
                    @click="confirmDelete(document)">
                    <span class="sr-only">Delete {{ document.file_name }}</span>
                </AppButton>
            </div>
        </td>
        <td class="md:w-16">
            <div class="flex flex-col items-start gap-1">
                <ul
                    :id="`load-card-document-labels-for-${document.file_name}-${document.id}`"
                    class="flex flex-wrap gap-1">
                    <li
                        v-for="label in document.labels"
                        :key="label"
                        class="mt-0.5 flex place-content-center whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-bold ring-1 ring-inset"
                        :class="{
                            'bg-iel-lightest-green text-iel-dark-green ring-iel-green/20':
                                label !== 'Last Saved RateCon',
                            'bg-iel-light-red text-iel-dark-red ring-iel-red/20': label === 'Last Saved RateCon'
                        }">
                        {{ label }}
                    </li>
                </ul>
            </div>
        </td>
        <td class="md:w-28">
            <div class="flex flex-wrap gap-1">
                <div
                    v-for="stop in stops"
                    :key="stop">
                    <span
                        v-if="stop"
                        class="rounded-md bg-iel-lightest-green px-1.5 py-0.5 text-sm font-bold text-iel-dark-green ring-1 ring-inset ring-iel-green/20">
                        {{ stop.label }}
                    </span>
                    <span v-else></span>
                </div>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

const props = defineProps({
    document: {
        type: Object,
        default: () => {}
    },
    object: Object,
    objectName: String,
    documentTypeOptions: Array,
    stopsOptions: Array,
    destroyRouteName: String,
    downloadRouteName: String,
    showRouteName: String,
    updateRouteName: String,
    multiple: Boolean
});

const { open: openAppDocsEditModal } = useModal('AppDocsEditModal');

const stops = computed(() => {
    if (props.objectName !== 'load') return;

    return (props.document.stops || []).map(stopId => {
        const item = props.object.stops.find(stop => stopId === stop.id);
        if (!item) return;
        return { label: `Stop #${item.order} (${item.location.name})`, value: item.id };
    });
});

function formatDate(date) {
    return new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
}

function edit(document) {
    openAppDocsEditModal({
        object: props.object,
        multiple: true,
        document,
        updateRouteName: props.updateRouteName,
        documentTypeOptions: props.documentTypeOptions,
        stopsOptions: props.stopsOptions,
        defaults: {
            file_name: document.file_name,
            labels: document.labels,
            stops: stops.value ?? []
        }
    });
}

function open(document) {
    usePopup().open(
        route(props.showRouteName, {
            [props.objectName]: props.object,
            media: document,
            embed: true
        })
    );
}

function confirmDelete(document) {
    if (confirm(`Are you sure you would like to delete "${document.file_name}"?`)) {
        document.loading = true;
        router.delete(route(props.destroyRouteName, [props.object, document]), {
            preserveScroll: true
        });
    }
}

function getDownloadRoute(object, document) {
    return route(props.downloadRouteName, { [props.objectName]: object.id, media: document.id });
}
</script>
