<template>
    <div class="grid gap-4">
        <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <!-- If you change the :model-value, make sure it follows the same validation as the invoice_amount in the LoadBillingDetailController.php   -->
            <AppInput
                :id="`load-${load.id}-invoice_amount`"
                :model-value="load.billingDetail?.invoice_amount"
                name="invoice_amount"
                :disabled="load.billingDetail?.invoiced_at"
                :url="url" />
            <AppDisplayField
                :id="`load-${load.id}-invoiced-at`"
                :value="formatDate(load.billingDetail?.invoiced_at)"
                label="Invoice Date" />
        </div>
        <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <AppDisplayField
                :id="`load-${load.id}-paid-at`"
                label="Invoice Paid Date"
                :value="formatDate(load.billingDetail?.paid_at)" />
            <AppDisplayField
                :id="`load-${load.id}-paid-amount`"
                label="Invoice Paid Amount"
                :value="load.billingDetail?.paid_amount || '-'" />
            <AppDisplayField
                :id="`load-${load.id}-amount-due`"
                label="Amount Due"
                :value="load.billingDetail?.amount_due || '-'" />
        </div>
        <div>
            <AppCheckbox
                :label="$t('Check Stops for Required Paperwork')"
                :disabled="true"
                name="check_stops_paperwork"
                :model-value="load.customer?.check_stops_paperwork" />
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { formatDate } from '@/helpers';

const page = usePage();
const load = computed(() => page.props.load);

const url = route('loads.billing-detail.update', page.props.load);
</script>
